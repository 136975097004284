import React, { useState } from "react";

import { AppBar, Button, Paper } from "@mui/material";

import { Amplify } from "aws-amplify";
import { translations } from "@aws-amplify/ui";
import { Authenticator } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";

import { Terms } from "../pages/Terms";
import logoWhite from "../assets/image/logo_white.png";
import logoWhiteBig from "../assets/image/logo_white_big.png";
import vocabularies from "../utils/vocabularies.json";

const COGNITO_USER_POOL_ID = process.env
  .REACT_APP_COGNITO_USER_POOL_ID as string;
const COGNITO_USER_POOL_CLIENT_ID = process.env
  .REACT_APP_COGNITO_USER_POOL_CLIENT_ID as string;

I18n.putVocabularies(translations);
I18n.setLanguage("ja");
I18n.putVocabulariesForLanguage("ja", vocabularies);

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: COGNITO_USER_POOL_CLIENT_ID,
      userPoolId: COGNITO_USER_POOL_ID,
      loginWith: {
        username: true,
      },
    },
  },
});

const formFields = {
  signUp: {
    username: {
      placeholder: "メールアドレスを入力",
      isRequired: true,
      label: "メールアドレス",
      order: 1,
      className: "require",
    },
    password: {
      label: "パスワード",
      isRequired: true,
      order: 2,
      className: "require",
    },
    confirm_password: {
      order: 3,
      className: "hidden",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "確認コード",
      isRequired: true,
      label: "確認コード",
    },
  },
};

const components = {
  Header() {
    return (
      <>
        <AppBar
          position="sticky"
          color="primary"
          sx={{ width: "100vw", margin: "0 calc(50% - 50vw)" }}
        >
          <div className="header__title">
            <img src={logoWhite} height={60} alt="ロゴ" />
            <img src={logoWhiteBig} height={60} alt="ロゴ" />
          </div>
        </AppBar>
        <Paper elevation={0} sx={{ m: 2, textAlign: "center" }}>
          EcoPayへようこそ
        </Paper>
      </>
    );
  },
  Footer() {
    const [isShow, setIsShow] = useState<boolean>(false);
    return (
      <>
        <Button
          variant="text"
          fullWidth
          sx={{ mx: "auto" }}
          onClick={() => setIsShow(true)}
        >
          利用規約
        </Button>
        {isShow && (
          <Paper
            elevation={0}
            sx={{ position: "absolute", top: 0, left: 0, mt: "80px" }}
          >
            <Button
              variant="text"
              fullWidth
              sx={{ mx: "auto" }}
              onClick={() => setIsShow(false)}
            >
              閉じる
            </Button>
            <Terms />
            <Button
              variant="text"
              fullWidth
              sx={{ mx: "auto", mb: "40px" }}
              onClick={() => setIsShow(false)}
            >
              閉じる
            </Button>
          </Paper>
        )}
      </>
    );
  },
};

export const CustomAuthenticator = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Authenticator components={components} formFields={formFields}>
      {children}
    </Authenticator>
  );
};
