import { Box, IconButton, Typography } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";

type Props = {
  balanceData: number;
  getCustomerInfo?: () => void;
};

export const Balance = ({ balanceData, getCustomerInfo }: Props) => {
  return (
    <>
      <Typography>保有EcoPay残高</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "center",
          mt: 1,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {balanceData.toLocaleString()}
        </Typography>
        <Typography fontSize="14px" sx={{ ml: 1 }}>
          円
        </Typography>
        {typeof getCustomerInfo !== "undefined" && (
          <IconButton
            color="primary"
            onClick={getCustomerInfo}
            sx={{ py: 0, mt: "auto" }}
          >
            <CachedIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
};
