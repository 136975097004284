import { Button, Paper, Typography } from "@mui/material";
import { QRCode } from "react-qrcode-logo";
import { useNavigate } from "react-router-dom";

type Props = {
  customerId: string;
  getCustomerInfo: () => void;
};

export const ManualCharge = ({ customerId, getCustomerInfo }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="h4" sx={{ mt: 2 }}>
        現金チャージ
      </Typography>
      <Typography variant="h6" sx={{ mt: 2, mb: 4 }}>
        この画面を担当者にお見せください
      </Typography>
      <QRCode value={customerId} size={256} bgColor={"#fff"} />
      <Paper elevation={0} sx={{ mx: 2, mt: 4 }}>
        <Button
          color="primary"
          variant="outlined"
          size="large"
          fullWidth
          onClick={() => {
            getCustomerInfo();
            navigate("/");
          }}
        >
          閉じる
        </Button>
      </Paper>
    </>
  );
};
