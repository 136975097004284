import React, { createContext, useContext, useState } from "react";

type Notice = string | null;
type Severity = "error" | "success" | "info" | "warning" | undefined;

interface NoticeContextType {
  notice: Notice;
  setNotice: (arg: Notice) => void;
  severity: Severity;
  setSeverity: (arg: Severity) => void;
}

const NoticeContext = createContext<NoticeContextType>({
  notice: null,
  setNotice: () => {},
  severity: undefined,
  setSeverity: () => {},
});

export const NoticeProvider = ({ children }: { children: React.ReactNode }) => {
  const [notice, setNotice] = useState<Notice>(null);
  const [severity, setSeverity] = useState<Severity>();

  return (
    <NoticeContext.Provider
      value={{ notice, setNotice, severity, setSeverity }}
    >
      {children}
    </NoticeContext.Provider>
  );
};

export const useNoticeContext = () => useContext(NoticeContext);
