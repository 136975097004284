import { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";

import termOfServiceMd from "../assets/markdown/service.md";
import termOfBalanceMd from "../assets/markdown/balance.md";

export const Terms = () => {
  const [termOfService, setTermOfService] = useState<string>("");
  const [termOfBalance, setTermOfBalance] = useState<string>("");
  const [selectTerm, setSelectTerm] = useState<number>(0);

  useEffect(() => {
    fetch(termOfServiceMd)
      .then((res) => res.text())
      .then((text) => setTermOfService(text));
    fetch(termOfBalanceMd)
      .then((res) => res.text())
      .then((text) => setTermOfBalance(text));
  }, []);

  return (
    <>
      <Typography textAlign="center" variant="h5" sx={{ mt: 1, mb: 2 }}>
        利用規約
      </Typography>
      <Box display="flex" flexDirection="column" width="200px" margin="auto">
        <Button variant="text" onClick={() => setSelectTerm(0)}>
          サービス利用規約
        </Button>
        <Button
          variant="text"
          sx={{ textTransform: "none" }}
          onClick={() => setSelectTerm(1)}
        >
          EcoPay残高利用規約
        </Button>
      </Box>
      <Box sx={{ width: "90%", mx: "auto", textAlign: "left" }}>
        {selectTerm === 0 && <ReactMarkdown children={termOfService} />}
        {selectTerm === 1 && <ReactMarkdown children={termOfBalance} />}
      </Box>
    </>
  );
};
