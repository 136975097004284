import { useState } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";

import CustomAxios from "../utils/CustomAxios";

type Props = {
  issuerId: string | undefined;
  selectRate: number | undefined;
  setSelectRate: (arg: number) => void;
  setIsRateOpen?: (arg: boolean) => void;
};

export const Rating = ({
  issuerId,
  selectRate,
  setSelectRate,
  setIsRateOpen,
}: Props) => {
  const [tempRate, setTempRate] = useState<number | undefined>(selectRate);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notice, setNotice] = useState<string | null>(null);
  const [severity, setSeverity] = useState<"success" | "error">();

  const postReview = (rate: number) => {
    setIsLoading(true);
    CustomAxios.post(`/customer/review/${issuerId}`, { rate: rate })
      .then(() => {
        setNotice("評価を投稿しました");
        setSeverity("success");
        setSelectRate(rate);
      })
      .catch(() => {
        setNotice("エラーが発生しました");
        setSeverity("error");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Container
        sx={{
          my: 2,
          display: "flex",
          justifyContent: "center",
          fontSize: "20px",
        }}
      >
        {Array(5)
          .fill(null)
          .map((_, i) => (
            <Button onClick={() => setTempRate(i + 1)} key={i}>
              <Typography
                variant="h5"
                sx={{
                  color: tempRate && i < tempRate ? "#f8b500" : "gray",
                }}
              >
                ★
              </Typography>
            </Button>
          ))}
      </Container>
      {!notice && !isLoading && (
        <>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              postReview(tempRate as number);
            }}
            disabled={!tempRate}
            sx={{ width: "120px", mx: 1 }}
          >
            投稿する
          </Button>
          {setIsRateOpen && (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setTempRate(undefined);
                setIsRateOpen(false);
              }}
              sx={{ width: "120px", mx: 1 }}
            >
              キャンセル
            </Button>
          )}
        </>
      )}
      {isLoading && <CircularProgress sx={{ my: 4 }} />}
      {notice && (
        <Alert
          severity={severity}
          onClose={() => {
            setNotice(null);
            setIsRateOpen && setIsRateOpen(false);
          }}
          sx={{ width: "70%", mx: "auto", my: "30px" }}
        >
          {notice}
        </Alert>
      )}
    </>
  );
};
