import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import HistoryIcon from "@mui/icons-material/History";
import { useNavigate } from "react-router-dom";

import { Balance } from "../components/Balance";
import main from "../assets/scss/main.module.scss";
import { useNoticeContext } from "../context/NoticeContext";

import howToImage from "../assets/image/EcoPay利用方法.jpg";
import video1 from "../assets/video/利用方法_チャージ編.mp4";
import video2 from "../assets/video/利用方法_支払い編.mp4";
import video3 from "../assets/video/利用方法_抽選編.mp4";
import thumbnail1 from "../assets/video/利用方法_チャージ編.png";
import thumbnail2 from "../assets/video/利用方法_支払い編.png";
import thumbnail3 from "../assets/video/利用方法_抽選編.png";

type Props = {
  getCustomerInfo: () => void;
  isCustomerLoading: boolean;
  reduceCO2Data: number;
  balanceData: number;
};

export const Home = ({
  getCustomerInfo,
  isCustomerLoading,
  reduceCO2Data,
  balanceData,
}: Props) => {
  const navigate = useNavigate();
  const { notice, setNotice, severity } = useNoticeContext();

  const [isChargeModalOpen, setIsChargeModalOpen] = useState<boolean>(false);
  const [videoTabSelect, setVideoTabSelect] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setVideoTabSelect(newValue);
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{ backgroundColor: main.colorLightGreen, py: 2 }}
      >
        <Paper
          elevation={1}
          sx={{
            width: "90%",
            mx: "auto",
            p: 2,
            borderRadius: "16px",
          }}
        >
          <>
            <Typography>あなたはこれまでに</Typography>
            {isCustomerLoading && <CircularProgress />}
            {!isCustomerLoading && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "center",
                  mt: 1,
                  mx: "auto",
                  width: "150px",
                  borderBottom: 1,
                  borderColor: "#FF0000",
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {(Math.round(reduceCO2Data * 10) / 10).toLocaleString()}
                </Typography>
                <Typography fontSize="14px" sx={{ ml: 1 }}>
                  g-CO2
                </Typography>
              </Box>
            )}
            <Typography>の削減に貢献しています</Typography>
          </>
        </Paper>
        <Paper
          elevation={1}
          sx={{
            width: "90%",
            mx: "auto",
            mt: 2,
            p: 2,
            borderRadius: "16px",
          }}
        >
          {isCustomerLoading && <CircularProgress />}
          {!isCustomerLoading && (
            <Balance
              balanceData={Number(balanceData)}
              getCustomerInfo={getCustomerInfo}
            />
          )}
          <Divider sx={{ mt: 2 }} />
          <Button variant="text" onClick={() => setIsChargeModalOpen(true)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: 1,
                width: "130px",
              }}
            >
              <AddCircleOutlineIcon fontSize="medium" />
              チャージする
            </Box>
          </Button>
          <Modal open={isChargeModalOpen}>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                bgcolor: "background.paper",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mx: 2 }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  color="primary"
                  sx={{
                    textAlign: "left",
                    my: 1,
                  }}
                >
                  チャージ方法を選択
                </Typography>
                <IconButton
                  onClick={() => setIsChargeModalOpen(false)}
                  aria-label="閉じる"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Button
                variant="text"
                onClick={() => {
                  navigate("/manualCharge");
                  setIsChargeModalOpen(false);
                }}
                sx={{ color: "#000", m: 0, p: 0 }}
              >
                <Box
                  width={80}
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  sx={{
                    pt: 2,
                    pb: 8,
                    px: 2,
                  }}
                >
                  <CurrencyYenIcon />
                  <Typography>現金</Typography>
                </Box>
              </Button>
            </Box>
          </Modal>

          <Button variant="text" onClick={() => navigate("/history")}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: 1,
                width: "130px",
              }}
            >
              <HistoryIcon fontSize="medium" />
              支払履歴
            </Box>
          </Button>
        </Paper>
      </Paper>

      {notice && (
        <Alert
          severity={severity}
          onClose={() => setNotice(null)}
          sx={{ width: "70%", mx: "auto", my: "30px" }}
        >
          {notice}
        </Alert>
      )}
      <div className="image_area">
        <img src={howToImage} alt="EcoPay利用方法" />
      </div>
      <Typography variant="h5" sx={{ my: "20px" }}>
        ご利用方法
      </Typography>
      <Tabs
        variant="fullWidth"
        centered
        value={videoTabSelect}
        onChange={handleTabChange}
        aria-label="利用方法動画選択"
        sx={{ mb: "5px" }}
      >
        <Tab label="チャージ" sx={{ m: "auto" }} />
        <Tab label="支払い" sx={{ m: "auto" }} />
        <Tab label="抽選" sx={{ m: "auto" }} />
      </Tabs>
      <div className="image_area">
        {videoTabSelect === 0 && (
          <video controls poster={thumbnail1}>
            <source src={`${video1}#t=0.001"`} type="video/mp4" />
          </video>
        )}
        {videoTabSelect === 1 && (
          <video controls poster={thumbnail2}>
            <source src={`${video2}#t=0.001"`} type="video/mp4" />
          </video>
        )}
        {videoTabSelect === 2 && (
          <video controls poster={thumbnail3}>
            <source src={`${video3}#t=0.001"`} type="video/mp4" />
          </video>
        )}
      </div>
    </>
  );
};
