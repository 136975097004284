import { useState, useEffect } from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import CustomAxios from "../utils/CustomAxios";
import { IssuerData } from "../type";

const REGION = process.env.REACT_APP_REGION as string;

export const Deal = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [issuerData, setIssuerData] = useState<IssuerData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!location?.state?.noReload) {
      setIsLoading(true);
      CustomAxios.get("/issuers", { params: { region: REGION } })
        .then((res) => {
          setIssuerData(res.data.issuers);
          localStorage.setItem("issuers", JSON.stringify(res.data.issuers));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      const issuers = localStorage.getItem("issuers");
      if (issuers) {
        setIssuerData(JSON.parse(issuers));
      }
    }
  }, []);

  return (
    <>
      <Typography variant="h5" sx={{ mt: 1, mb: 2 }}>
        お得情報
      </Typography>
      {/* {isLoading && <CircularProgress />}
      {!isLoading &&
        issuerData.map((item, index) => (
          <Paper
            key={index}
            sx={{
              m: 2,
              px: 2,
              py: 2,
              textAlign: "left",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/store/${item.uuid}`)}
          >
            <Typography>{item.name}</Typography>
          </Paper>
        ))} */}
    </>
  );
};
