import { useState, useEffect } from "react";
import jsQR from "jsqr";
import Webcam from "react-webcam";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import StoreIcon from "@mui/icons-material/Store";
import { useNavigate } from "react-router-dom";

import { Balance } from "../components/Balance";
import { Rating } from "../components/Rating";
import { useNoticeContext } from "../context/NoticeContext";
import CustomAxios from "../utils/CustomAxios";
import main from "../assets/scss/main.module.scss";
import paymentSound from "../assets/sound/payment.mp3";

const VIDEO_CONST = {
  width: 375,
  height: 375,
  facingMode: "environment",
};

const PAYMENT_SOUND = new Audio(paymentSound);

type Props = {
  getCustomerInfo: () => void;
  balanceData: number;
};

export const Scan = ({ getCustomerInfo, balanceData }: Props) => {
  const navigate = useNavigate();
  const { setNotice, setSeverity } = useNoticeContext();

  const [issuerId, setIssuerId] = useState<string>("");
  const [issuerName, setIssuerName] = useState<string>("");
  const [issuerType, setIssuerType] = useState<string>("");
  const [point, setPoint] = useState<number | null>(null);
  const [isScanLoading, setIsScanLoading] = useState<boolean>(false);
  const [isSendLoading, setIsSendLoading] = useState<boolean>(false);
  const [isScanOpen, setIsScanOpen] = useState<boolean>(true);
  const [isInputAreaOpen, setIsInputAreaOpen] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [inputPay, setInputPay] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [selectRate, setSelectRate] = useState<number>();

  const refreshScan = () => {
    setIsScanLoading(false);
    navigate("/");
    setIssuerId("");
  };

  const handleCapture = () => {
    const video = document.getElementById("video") as HTMLImageElement;
    const cvs = document.getElementById("camera-canvas") as HTMLCanvasElement;
    if (!cvs) {
      return;
    }
    const ctx = cvs.getContext("2d");
    cvs.width = video.clientWidth;
    cvs.height = video.clientHeight;
    ctx?.drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
    const imageData = ctx?.getImageData(
      0,
      0,
      video.clientWidth,
      video.clientHeight
    );
    if (!imageData) {
      return;
    }
    const code = jsQR(imageData.data, video.clientWidth, video.clientHeight);
    if (code) {
      setIsScanLoading(true);
      setIssuerId(code.data);
      CustomAxios.get(`/issuer/${code.data}`, { params: { no_pic: true } })
        .then((res) => {
          setIssuerName(res.data.name);
          setSelectRate(res.data.customerRate);
          setIssuerType(res.data.type);
          if (res.data.qrcode_type === "pay") {
            setIsInputAreaOpen(true);
            setIsScanLoading(false);
            setIsScanOpen(false);
          } else {
            refreshScan();
          }
        })
        .catch((err) => {
          console.log(err);
          setNotice("QRコードが不正です");
          setSeverity("error");
          refreshScan();
        });
    }
  };

  useEffect(() => {
    if (!issuerId) {
      const interval = setInterval(() => {
        handleCapture();
      }, 500);
      return () => {
        clearInterval(interval);
      };
    }
  });

  const handleInputPay = (v: string) => {
    if (v.match(/^[0-9]*$/)) {
      setInputPay(v);
      setIsInputError(Number(v) > balanceData);
    }
  };

  const onSend = () => {
    setIsSendLoading(true);
    CustomAxios.post(`/customer/issuer/${issuerId}`, {
      value: Number(inputPay),
    })
      .then((res) => {
        if (res.data?.result === "Success") {
          setIssuerName(res.data.issuer_name);
          setPoint(res.data.point);
          setIsModalOpen(true);
          getCustomerInfo();

          // 決済成功音を鳴らす
          PAYMENT_SOUND.currentTime = 0;
          PAYMENT_SOUND.play();
        } else {
          setNotice("QRコードが不正です");
          setSeverity("error");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsSendLoading(false);
      });
  };

  const handleClose = () => {
    setIsModalOpen(false);
    refreshScan();
  };

  return (
    <>
      {isScanOpen && (
        <>
          <Webcam
            id="video"
            className="scan_video"
            audio={false}
            width={VIDEO_CONST.width}
            height={VIDEO_CONST.height}
            screenshotFormat="image/jpeg"
            videoConstraints={VIDEO_CONST}
          />
          {isScanLoading && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!isScanLoading && (
            <>
              <div className="scan_canvas">
                <canvas id="camera-canvas" className="scan_canvas" />
              </div>
              <Box
                width="100%"
                height="calc((100% - 300px) / 2)"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                  background: "rgba(0,0,0,0.5);",
                }}
              ></Box>
              <Box
                width="calc((100% - 300px) / 2)"
                height="300px"
                sx={{
                  position: "absolute",
                  bottom: "50%",
                  right: 0,
                  transform: "translate(0%, 50%)",
                  background: "rgba(0,0,0,0.5);",
                }}
              ></Box>
              <Box
                width="calc((100% - 300px) / 2)"
                height="300px"
                sx={{
                  position: "absolute",
                  bottom: "50%",
                  left: 0,
                  transform: "translate(0%, 50%)",
                  background: "rgba(0,0,0,0.5);",
                }}
              ></Box>
              <Box
                width="100%"
                height="calc((100% - 300px) / 2)"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                  background: "rgba(0,0,0,0.5);",
                }}
              ></Box>
              <Box
                width="300px"
                height="300px"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  border: "10px dashed",
                  borderColor: main.colorGreen,
                  backgroundColor: "transparent",
                  boxSizing: "initial",
                }}
              ></Box>
            </>
          )}
          <Paper
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "transparent",
              pb: 2,
            }}
            elevation={0}
          >
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={refreshScan}
              startIcon={<CloseIcon />}
              sx={{ m: 2 }}
            >
              閉じる
            </Button>
          </Paper>
        </>
      )}
      {isInputAreaOpen && (
        <>
          <div className="issuer_name">
            <StoreIcon
              fontSize="large"
              sx={{
                p: "10px",
                backgroundColor: main.colorGreen,
                color: "#fff",
                borderRadius: "50%",
                width: "75px",
                minWidth: "75px",
                maxWidth: "75px",
                height: "75px",
                minHeight: "75px",
                maxHeight: "75px",
              }}
            />
            <span>{issuerName}</span>
          </div>
          <Paper
            elevation={0}
            sx={{ backgroundColor: main.colorLightGreen, py: 2 }}
          >
            <Paper
              elevation={1}
              sx={{
                width: "90%",
                mx: "auto",
                p: 2,
                borderRadius: "16px",
              }}
            >
              <Balance balanceData={Number(balanceData)} />
            </Paper>
          </Paper>
          <FormControl sx={{ m: "30px" }}>
            <InputLabel
              htmlFor="component-simple"
              sx={{ backgroundColor: "#fff", fontSize: "20px", px: "5px" }}
            >
              支払い金額を入力
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              startAdornment={
                <InputAdornment
                  position="start"
                  sx={{ fontSize: 34, fontWeight: "bold" }}
                >
                  <CurrencyYenIcon />
                </InputAdornment>
              }
              autoFocus={true}
              type="number"
              required={true}
              value={inputPay}
              onChange={(e) => handleInputPay(e.target.value)}
              sx={{ fontSize: 34, fontWeight: "bold" }}
              inputProps={{ min: "0" }}
              error={isInputError}
            />
            {isInputError && (
              <FormHelperText error sx={{ fontSize: "14px" }}>
                金額が不足しています
              </FormHelperText>
            )}
          </FormControl>
          <Paper
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              m: 2,
              pb: 2,
            }}
            elevation={0}
          >
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              onClick={() => {
                setIsConfirmOpen(true);
                setIsInputAreaOpen(false);
              }}
              disabled={isInputError || inputPay === "" || inputPay === "0"}
              startIcon={<CheckIcon />}
              sx={{ mb: 2 }}
            >
              確認
            </Button>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              fullWidth
              onClick={refreshScan}
              startIcon={<CloseIcon />}
            >
              閉じる
            </Button>
          </Paper>
        </>
      )}
      {isConfirmOpen && (
        <>
          <div className="issuer_name">
            <StoreIcon
              fontSize="large"
              sx={{
                p: "10px",
                backgroundColor: main.colorGreen,
                color: "#fff",
                borderRadius: "50%",
                width: "75px",
                minWidth: "75px",
                maxWidth: "75px",
                height: "75px",
                minHeight: "75px",
                maxHeight: "75px",
              }}
            />
            <span>{issuerName}</span>
          </div>
          <Paper
            elevation={0}
            sx={{ my: "50px", fontWeight: "bold", fontSize: "56px" }}
          >
            ￥{Number(inputPay).toLocaleString()}
          </Paper>
          <Paper
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              m: 2,
              pb: 2,
            }}
            elevation={0}
          >
            {isSendLoading && <CircularProgress />}
            {!isSendLoading && (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    setIsConfirmOpen(false);
                    setIsInputAreaOpen(true);
                  }}
                  startIcon={<ArrowBackIcon />}
                  sx={{ mb: 2 }}
                >
                  戻る
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={onSend}
                  endIcon={<CurrencyYenIcon />}
                >
                  支払う
                </Button>
              </>
            )}
          </Paper>
          <Modal open={isModalOpen}>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                m: 2,
                bgcolor: "background.paper",
                borderRadius: "16px",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                color="primary"
                sx={{
                  textAlign: "center",
                  p: 1,
                  backgroundColor: main.colorGreen,
                  color: "#fff",
                  borderRadius: "16px 16px 0 0",
                }}
              >
                支払い完了
              </Typography>
              <div className="issuer_name">
                <StoreIcon
                  fontSize="medium"
                  sx={{
                    p: "10px",
                    backgroundColor: main.colorGreen,
                    color: "#fff",
                    borderRadius: "50%",
                    width: "50px",
                    minWidth: "50px",
                    maxWidth: "50px",
                    height: "50px",
                    minHeight: "50px",
                    maxHeight: "50px",
                  }}
                />
                <span>{issuerName}</span>
              </div>
              <Grid
                container
                sx={{ textAlign: "center", alignItems: "center", p: 2 }}
              >
                <Grid item xs={6}>
                  <Typography variant="h4">
                    ￥{Number(inputPay).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ textAlign: "center" }}>
                    付与ポイント
                  </Typography>
                  <Paper elevation={0} sx={{ textAlign: "center", mt: 1 }}>
                    <Chip
                      label={`+${point}pt`}
                      color="info"
                      sx={{ color: "#FFF", px: 2 }}
                    ></Chip>
                  </Paper>
                </Grid>
              </Grid>
              {(issuerType === "resutaurant" || issuerType === "event") && (
                <Container sx={{ mt: 4, textAlign: "center" }}>
                  <Typography>このお店を評価する</Typography>
                  <Rating
                    issuerId={issuerId}
                    selectRate={selectRate}
                    setSelectRate={setSelectRate}
                  />
                </Container>
              )}
              <Paper sx={{ ml: 2, mr: 2, mb: 2, mt: 10 }}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={handleClose}
                >
                  OK
                </Button>
              </Paper>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};
