import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

const API_URL = process.env.REACT_APP_API_URL;

const CustomAxios = axios.create({ baseURL: API_URL });

CustomAxios.interceptors.request.use(
  (config) =>
    new Promise((resolve) => {
      fetchAuthSession({ forceRefresh: true }).then((res) => {
        const tokens = res.tokens;
        const idToken = tokens?.idToken?.toString();
        config.headers.Authorization = idToken;
        resolve(config);
      });
    }),
  (error) => {
    return Promise.reject(error);
  }
);

export default CustomAxios;
