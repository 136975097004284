import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  Paper,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import ReviewsIcon from "@mui/icons-material/Reviews";

import { Rating } from "../components/Rating";
import CustomAxios from "../utils/CustomAxios";
import { issuerDetailData } from "../type";

import main from "../assets/scss/main.module.scss";

export const MapDetail = () => {
  const navigate = useNavigate();

  const { issuerId } = useParams();

  const [issuerDetailData, setIssuerDetailData] = useState<issuerDetailData>();
  const [selectRate, setSelectRate] = useState<number>();
  const [isRateOpen, setIsRateOpen] = useState<boolean>(false);

  useEffect(() => {
    CustomAxios.get(`/issuer/${issuerId}`)
      .then((res) => {
        setIssuerDetailData(res.data as issuerDetailData);
        setSelectRate(res.data.customerRate);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [issuerId]);

  const textToLink = (comment: string) => {
    const regexp_url = /(https?:\/\/[\w/:%#$&?()~.=+-]+)/g;
    let linkedComment = comment.replace(
      regexp_url,
      '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
    );

    return linkedComment;
  };

  const calcLastImg = (index: number, length: number): number => {
    return length % 2 !== 0 && length - 1 === index ? 2 : 1;
  };

  const calcRatePercent = (rate?: number): number => {
    return rate ? (rate / 5) * 100 : 0;
  };

  const editDateFormat = (dateStr?: string): string => {
    if (dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleDateString("ja-JP");
    } else {
      return "";
    }
  };

  return (
    <>
      <Paper elevation={0} sx={{ my: 2, position: "relative" }}>
        <IconButton
          color="primary"
          size="large"
          onClick={() => navigate("/map", { state: { noReload: true } })}
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            my: "auto",
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography
          variant="h5"
          width="100%"
          sx={{ px: "50px", wordBreak: "break-all" }}
        >
          {issuerDetailData?.name ? issuerDetailData.name : "読込中..."}
        </Typography>
      </Paper>
      {!issuerDetailData && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {issuerDetailData && (
        <Paper
          elevation={0}
          sx={{ width: "500px", maxWidth: "100%", mx: "auto" }}
        >
          {issuerDetailData.urls && (
            <ImageList
              sx={{ width: "100%", height: "auto" }}
              cols={issuerDetailData.urls.length > 1 ? 2 : 1}
            >
              {issuerDetailData.urls.map((item, index) => (
                <ImageListItem
                  key={index}
                  cols={calcLastImg(index, issuerDetailData.urls.length)}
                >
                  <img src={item} alt="" loading="lazy" />
                </ImageListItem>
              ))}
            </ImageList>
          )}
          <Container>
            <Typography>
              この{issuerDetailData.type === "event" ? "イベント" : "お店"}
              はこれまでに
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
                mt: 1,
                mx: "auto",
                width: "150px",
                borderBottom: 1,
                borderColor: "#FF0000",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {(
                  Math.round((issuerDetailData.totalReduleCo2 / 1000) * 10) / 10
                ).toLocaleString()}
              </Typography>
              <Typography fontSize="14px" sx={{ ml: 1 }}>
                kg-CO2
              </Typography>
            </Box>
            <Typography>の削減に貢献しています</Typography>
          </Container>
          <Divider sx={{ my: 2, mx: "auto", width: "80%" }} />
          {!isRateOpen && (
            <Container
              sx={{
                my: 2,
                display: "flex",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Button
                variant="text"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <a
                  href={`https://www.google.com/maps/dir/?api=1&destination=${issuerDetailData.position.lat},${issuerDetailData.position.lng}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <AssistantDirectionIcon
                    fontSize="large"
                    sx={{ color: main.colorDarkGreen }}
                  />
                  <Typography>ここへ行く</Typography>
                </a>
              </Button>
              {issuerDetailData.type === "restaurant" && (
                <Button
                  variant="text"
                  onClick={() => setIsRateOpen(true)}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <ReviewsIcon
                    fontSize="large"
                    sx={{ color: main.colorDarkGreen }}
                  />
                  <Typography>評価する</Typography>
                </Button>
              )}
            </Container>
          )}
          {isRateOpen && (
            <Rating
              issuerId={issuerId}
              selectRate={selectRate}
              setSelectRate={setSelectRate}
              setIsRateOpen={setIsRateOpen}
            />
          )}
          <Divider sx={{ my: 2, mx: "auto", width: "80%" }} />
          {issuerDetailData.type === "restaurant" && (
            <>
              <Typography variant="h6" fontWeight="bold">
                評価
              </Typography>
              <Container
                sx={{
                  my: 2,
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                {issuerDetailData.averageRate && (
                  <Typography
                    variant="h5"
                    sx={{
                      background: `linear-gradient(to right, #f8b500 ${calcRatePercent(
                        issuerDetailData.averageRate
                      )}%, gray ${calcRatePercent(
                        issuerDetailData.averageRate
                      )}%)`,
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    ★★★★★
                  </Typography>
                )}
                {!issuerDetailData.averageRate && (
                  <Typography>まだ評価はありません</Typography>
                )}
              </Container>
            </>
          )}
          <Typography variant="h6" fontWeight="bold">
            基本情報
          </Typography>
          <Container
            sx={{
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              my: 2,
            }}
          >
            {issuerDetailData.address && (
              <Container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <PlaceIcon sx={{ color: main.colorDarkGreen }} />
                {issuerDetailData.address}
              </Container>
            )}
            {issuerDetailData.tel && (
              <Container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <PhoneIcon sx={{ color: main.colorDarkGreen }} />
                {issuerDetailData.tel}
              </Container>
            )}
            {issuerDetailData.email && (
              <Container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <EmailIcon sx={{ color: main.colorDarkGreen }} />
                {issuerDetailData.email}
              </Container>
            )}
            {issuerDetailData.web && (
              <Container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <LanguageIcon sx={{ color: main.colorDarkGreen }} />
                {issuerDetailData.web}
              </Container>
            )}
            {issuerDetailData.businessHour && (
              <Container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <AccessTimeIcon sx={{ color: main.colorDarkGreen }} />
                {issuerDetailData.businessHour}
              </Container>
            )}
            {(issuerDetailData.eventStartDate ||
              issuerDetailData.eventEndDate) && (
              <Container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <CalendarMonthIcon sx={{ color: main.colorDarkGreen }} />
                {issuerDetailData.eventStartDate ===
                  issuerDetailData.eventEndDate && (
                  <>{editDateFormat(issuerDetailData.eventStartDate)}</>
                )}
                {issuerDetailData.eventStartDate !==
                  issuerDetailData.eventEndDate && (
                  <>
                    {editDateFormat(issuerDetailData.eventStartDate)} ~{" "}
                    {editDateFormat(issuerDetailData.eventEndDate)}
                  </>
                )}
              </Container>
            )}
          </Container>
          {issuerDetailData.description && (
            <>
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
                詳細情報
              </Typography>
              <Container
                sx={{
                  whiteSpace: "break-spaces",
                  px: 2,
                  overflowWrap: "anywhere",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: textToLink(issuerDetailData.description),
                  }}
                ></div>
              </Container>
            </>
          )}
          {issuerDetailData.type === "event" && (
            <>
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
                出店者一覧
              </Typography>
              {issuerDetailData.eventStoreList.map((name: string) => (
                <Container sx={{ my: 1 }}>{name}</Container>
              ))}
            </>
          )}
        </Paper>
      )}
    </>
  );
};
